import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  Grid,
  Button,
  Typography,
  Container,
  Divider,
  Box,
} from "@material-ui/core";
import Image from "../components/atoms/Image";
import SectionHeader from "../components/molecules/SectionHeader";
import Section from "../components/organisms/Section";
import Pc from "../images/pc.png";
import Smartphone from "../images/smartphone.png";
import About from "../images/about.png";
import AOS from "aos";
import "aos/dist/aos.css";
import SectionAlternate from "../components/organisms/SectionAlternate";
import Accordion from "../components/organisms/Accordion";
import TermsConditionsDialogWithoutInteraction from "../components/organisms/TermsConditionsDialogWithoutInteraction";

const useStyles = makeStyles((theme) => ({
  image: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
  },
  LandingImageTwo: {
    width: 550,
    height: 750,
    position: "absolute",
    bottom: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      width: 180,
      height: 250,
      right: "20vw",
    },
  },
  marginButton: {
    marginLeft: "1vw",
  },
  aboutImage: {
    width: "45vw",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  list: {
    listStyle: "none",
    paddingLeft: 100,
    paddingRight: 150,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 20,
    },
  },
  liStyle: {
    marginBottom: 20,
    borderBottom: "1px solid #ebebeb",
  },
  pstyle: {
    paddingLeft: 60,
  },
  sectionAlternate: {
    background: "transparent",
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },
  bottom: {
    backgroundColor: "#343537",
    color: "#fff",
  },

  divider: {
    marginTop: "2vh",
    marginBottom: "4vh",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    AOS.init();
  }, []);

  const [openDialog, setOpenDialog] = useState(false);

  const faq = [
    {
      id: "faq-1",
      title: "L'application Medclick",
      subtitle: "Comment puis-je installer l’application ?",
      text: "Medclick utilise une technologie appelée progressive web app. Cela signifie que vous pouvez la télécharger sans passer par un store. Rendez-vous dans les options de votre navigateur et cliquez sur 'Ajouter à l’écran d’accueil'.",
    },
    {
      id: "faq-2",
      title: "Est-ce payant ?",
      subtitle: "Ou cela le deviendra-t-il ?",
      text: "Pour le moment, l'application est gratuite dans sa version bêta. Nous réfléchissons à des moyens de financement.",
    },
    {
      id: "faq-3",
      title: "Que faites-vous des informations que j'enregistre ?",
      subtitle: "Vos données sont-elles utilisées ?",
      text: "Vos informations sont principalement utilisées pour générer vos statistiques personnelles. Certaines données anonymisées peuvent être utilisées à des fins marketing.",
    },
    {
      id: "faq-4",
      title: "Mise à jour",
      subtitle: "Comment puis-je contribuer à améliorer l'application ?",
      text: "N'hésitez pas à nous contacter pour signaler un bug ou proposer une idée afin d'améliorer Medclick.",
    },
  ];

  const handleClickOpen = () => setOpenDialog(true);
  const handleClose = () => setOpenDialog(false);

  return (
    <>
      {/* Section Hero */}
      <Section>
        <Grid container justify="space-between" spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={6} data-aos={"fade-up"}>
            <SectionHeader
              title={
                <span>
                  <Typography variant={"h1"} color="primary">
                    Medclick
                  </Typography>
                  <Typography
                    component="span"
                    variant="inherit"
                    color="primary"
                  >
                    L'application web smart medical.
                  </Typography>
                </span>
              }
              subtitle="Solution pour médecin en formation"
              ctaGroup={[
                <Link to="/login">
                  <Button
                    variant="contained"
                    color="primary"
                    size={isMd ? "large" : "medium"}
                  >
                    Se connecter
                  </Button>
                </Link>,
                <Link to="/register">
                  <Button
                    variant="outlined"
                    color="primary"
                    size={isMd ? "large" : "medium"}
                  >
                    S'enregistrer
                  </Button>
                </Link>,
              ]}
              align={isMd ? "left" : "center"}
              disableGutter
              titleVariant="h3"
            />
            <Divider color="primary" className={classes.divider} />
          </Grid>
          <Grid
            item
            container
            justify="center"
            alignItems="center"
            xs={12}
            md={6}
          >
            <div className={classes.image}>
              <Image
                src={Pc}
                alt="TheFront Company"
                className={classes.LandingImageOne}
              />
              <Image
                src={Smartphone}
                alt="TheFront Company"
                className={classes.LandingImageTwo}
              />
            </div>
          </Grid>
        </Grid>
      </Section>

      {/* Section About */}
      <Section name="about" className={classes.about}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} data-aos="fade-up">
            <img
              src={About}
              className={classes.aboutImage}
              alt="About Medclick"
            />
          </Grid>

          <Grid item xs={12} md={8} data-aos="fade-up">
            <Typography variant="h3">Qu'est-ce que</Typography>
            <Typography variant="h3" color="primary">
              MEDCLICK?
            </Typography>
            <ol className={classes.list}>
              {[
                "Une solution créée par des assistants pour des assistants, afin d'apporter une solution pour la tenue de ton 'carnet de stage'.",
                "Un moyen de rechercher plus facilement les codes INAMI des interventions que tu réalises.",
                "Un outil capable de générer automatiquement ton carnet de stage au format Excel. Ainsi, il te sera toujours possible de le modifier.",
                "Une application qui te permet de suivre ton évolution au jour le jour.",
              ].map((text, index) => (
                <li key={index} className={classes.liStyle} data-aos="fade-up">
                  <Typography variant="h4" color="primary">
                    0{index + 1}
                  </Typography>
                  <Typography
                    variant="h5"
                    className={classes.pstyle}
                    align="justify"
                  >
                    {text}
                  </Typography>
                </li>
              ))}
            </ol>
          </Grid>
        </Grid>
      </Section>

      {/* Section FAQ */}
      <SectionAlternate
        name="faq"
        innerNarrowed
        className={classes.sectionAlternate}
      >
        <Container>
          <Typography variant="h4" align="center" gutterBottom>
            FAQ
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Consultez notre FAQ pour répondre à vos questions.
          </Typography>
          <Accordion items={faq} />
        </Container>
      </SectionAlternate>

      {/* Section Bas de page */}
      <Box className={classes.bottom}>
        <Container>
          <Grid
            container
            spacing={4}
            justifyContent="space-between"
            alignItems="center"
            direction={{ xs: "column", md: "row" }} // Colonne pour mobile, ligne pour desktop
          >
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
              {" "}
              {/* Alignement central */}
              <a
                href="mailto:medclick.info@gmail.com"
                style={{ color: "#fff", textDecoration: "none" }}
                aria-label="Nous contacter"
              >
                <Typography>Nous contacter</Typography>
              </a>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
              <Typography
                onClick={handleClickOpen}
                style={{ cursor: "pointer" }}
              >
                Conditions d'utilisation
              </Typography>
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
              {" "}
              {/* Alignement central */}
              <Typography>Version: 3.1</Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Dialogue des Conditions d'Utilisation */}
      <TermsConditionsDialogWithoutInteraction
        open={openDialog}
        handleClose={handleClose}
      />
    </>
  );
};

export default HomePage;
