import axios from "axios";
import { YEARS_API, EXCEL_API } from "../config";

/**
 * Recherche toutes les années de formation de l'utilisateur.
 */
function findAll() {
  return axios.get(YEARS_API).then((response) => response.data["hydra:member"]);
}

/**
 * Permet de créer une nouvelle année de formation.
 */
function create(year) {
  return axios.post(YEARS_API + "/create", year);
}

/**
 * Permet de rechercher une année en fonction de l'id.
 */
function find(id) {
  return axios.get(YEARS_API + "/" + id);
}

/**
 * Permet de modifier une année.
 */
function update(id, year) {
  return axios.put(YEARS_API + "/" + id, year);
}

/**
 * @param id de l'année désirée.
 * Permet de télécharger l'excel de manière authentifié.
 */
function excel(id, callback) {
  return axios({
    url: EXCEL_API + "/" + id,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Relevé.xlsx");
    document.body.appendChild(link);
    link.click();
    callback();
  });
}

/**
 * @param {string} id - ID de l'année désirée.
 * @param {Function} [callback] - Fonction appelée après le téléchargement.
 * Permet de télécharger l'excel V2 de manière authentifiée.
 */
function excelNewVersion(id, callback) {
  if (!id) {
    console.error("L'ID est requis pour télécharger le fichier.");
    return;
  }

  axios({
    url: `${EXCEL_API}2/${id}`,
    method: "GET",
    responseType: "blob", // important
    headers: {
      Accept: "application/vnd.ms-excel",
    },
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Relevé.xlsx");
      document.body.appendChild(link);
      link.click();

      // Suppression de l'élément et révocation de l'URL Blob
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      // Appel du callback si fourni
      if (typeof callback === "function") {
        callback();
      }
    })
    .catch((error) => {
      console.error("Erreur lors du téléchargement :", error);
    });
}

const exportedObject = {
  findAll,
  create,
  find,
  update,
  excel,
  excelNewVersion,
};

export default exportedObject;
