import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Box,
} from "@material-ui/core";
import termsAPI from "../../Services/termsAPI";

const TermsConditionsDialogWithoutInteraction = ({ open, handleClose }) => {
  const [terms, setTerms] = useState("");
  const [publishedAt, setPublishedAt] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTermsConditions = async () => {
      setIsLoading(true);
      try {
        const response = await termsAPI.getTerms();
        const date = new Date(response.data.publishedAt);
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${date.getFullYear()}`;
        setTerms(response.data.content);
        setPublishedAt(formattedDate);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des conditions générales :",
          error
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchTermsConditions();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="lg"
    >
      <DialogTitle>Conditions générales</DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <DialogContentText>
            <p>Date de publication : {publishedAt}</p>
            <div dangerouslySetInnerHTML={{ __html: terms }} />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Retour
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsConditionsDialogWithoutInteraction;
