import React from "react";

// Créer un contexte avec acceptTerms
export default React.createContext({
  isAuthenticated: false,
  setIsAuthenticated: (value) => {},

  userName: "",
  setUserName: (value) => {},

  role: "",
  setRole: (value) => {},

  loading: false,
  setLoading: (value) => {},

  years: "",
  setYears: (value) => {},

  acceptTerms: false,
  setAcceptTerms: (value) => {},
});
