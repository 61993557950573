import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import authAPI from "../Services/authAPI";
import AuthContext from "../contexts/AuthContext";
import marketingAPI from "../Services/marketingAPI";
import { Button, Grid, Typography, Fade } from "@material-ui/core";
import Section from "../components/organisms/Section";
import SectionHeader from "../components/molecules/SectionHeader";
import LearnMoreLink from "../components/atoms/LearnMoreLink";
import { makeStyles } from "@material-ui/core";
import Field from "../components/atoms/Field";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: "0 auto",
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  adContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
    flexDirection: "column",
    cursor: "pointer",
  },
  adImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  progressBarContainer: {
    position: "absolute",
    bottom: "5%",
    width: "90%",
    height: "2%",
    // Utiliser une couleur similaire à theme.palette.primary.main, mais plus claire et transparente
    backgroundColor: "rgba(63, 81, 181, 0.2)",
    borderRadius: "15px",
    overflow: "hidden",
  },
  progressBarFiller: {
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    width: "0%",
  },
}));

const LoginPage = ({ history }) => {
  const classes = useStyles();
  const { setIsAuthenticated, setRole, setUserName, setLoading } =
    useContext(AuthContext);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [showAd, setShowAd] = useState(false);
  const [adImageUrl, setAdImageUrl] = useState(null);
  const [attemptedOnce, setAttemptedOnce] = useState(false);
  const [authComplete, setAuthComplete] = useState(false);
  const [adComplete, setAdComplete] = useState(false);
  const [progressStart, setProgressStart] = useState(false);

  // Durée par défaut : 5s
  // finalAdDuration sera mise à jour avec la valeur venant du localStorage s'il y en a une.
  const [finalAdDuration, setFinalAdDuration] = useState(5);

  // Charger l'image de la campagne au montage
  useEffect(() => {
    const campaign = localStorage.getItem("campaign")
      ? JSON.parse(localStorage.getItem("campaign"))
      : null;
    if (campaign) {
      setAdImageUrl(getAdImageUrl(campaign));
    }
  }, []);

  // Rediriger lorsque l'authentification et l'affichage publicitaire sont terminés
  useEffect(() => {
    if (authComplete && adComplete) {
      history.replace("/front");
    }
  }, [authComplete, adComplete, history]);

  const handleChange = ({ currentTarget }) => {
    const { name, value } = currentTarget;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!attemptedOnce) {
      const campaign = localStorage.getItem("campaign")
        ? JSON.parse(localStorage.getItem("campaign"))
        : null;

      let adDuration = 5; // Valeur par défaut
      if (campaign && campaign.duration) {
        const parsedDuration = parseFloat(campaign.duration);
        if (!isNaN(parsedDuration) && parsedDuration > 0) {
          adDuration = parsedDuration;
        }
      }

      // On met à jour la durée finale dans le state
      setFinalAdDuration(adDuration);

      if (campaign) {
        displayAdvertisement(adDuration, campaign);
      } else {
        setAdComplete(true);
      }

      setAttemptedOnce(true);
    }

    try {
      await authAPI.authenticate(credentials);
      const userRole = await authAPI.getRole();
      const userName = await authAPI.getIdentity();
      const termsStatus = userName.acceptedTerms;

      localStorage.setItem("acceptedTerms", termsStatus);

      setRole(userRole);
      setUserName(userName);
      setIsAuthenticated(true);
      setError("");
      setAuthComplete(true);
    } catch (error) {
      setError(
        "Les informations ne correspondent pas ou l'email n'a pas été validé"
      );
    } finally {
      setLoading(false);
    }
  };

  const displayAdvertisement = (adDuration, campaign) => {
    setShowAd(true);
    setAdComplete(false);
    setProgressStart(false);

    // Démarre l'animation après un cycle pour permettre l'application du style initial
    setTimeout(() => {
      setProgressStart(true);
    }, 50);

    const delay = adDuration * 1000;

    setTimeout(() => {
      setShowAd(false);
      setAdComplete(true);
    }, delay);
  };

  const getAdImageUrl = (campaign) => {
    if (!campaign || !campaign.formats) return null;

    if (window.innerWidth <= 600) {
      return campaign.formats.smartphone;
    } else if (window.innerWidth <= 900) {
      return campaign.formats.tablet_portrait;
    } else if (window.innerWidth <= 1200) {
      return campaign.formats.tablet_landscape;
    } else if (window.innerWidth <= 1600) {
      return campaign.formats.screen_14_inch;
    } else {
      return campaign.formats.large_screen;
    }
  };

  const handleAdClick = () => {
    try {
      const link = JSON.parse(localStorage.getItem("campaign"));
      if (link && link.redirect_url && isValidUrl(link.redirect_url)) {
        marketingAPI.incrementClic(link.id);
        window.open(link.redirect_url, "_blank");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de la campagne:", error);
    }
  };

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      {showAd && adImageUrl && (
        <Fade in={showAd} timeout={{ enter: 200, exit: 200 }}>
          <div className={classes.adContainer} onClick={handleAdClick}>
            <img src={adImageUrl} alt="Publicité" className={classes.adImage} />
            <div className={classes.progressBarContainer}>
              <div
                className={classes.progressBarFiller}
                style={{
                  width: progressStart ? "100%" : "0%",
                  transition: progressStart
                    ? `width ${finalAdDuration}s linear`
                    : "none",
                }}
              ></div>
            </div>
          </div>
        </Fade>
      )}

      <Section className={classes.section}>
        <div className={classes.formContainer}>
          <SectionHeader
            title="Se connecter"
            subtitle={
              <span>
                Pas encore inscrit?{" "}
                <Link to="/register">
                  <LearnMoreLink
                    title="S'enregistrer."
                    typographyProps={{ variant: "h6" }}
                  />
                </Link>
              </span>
            }
            titleProps={{
              variant: "h3",
            }}
          />

          <form name="login-form" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Field
                  placeholder="Votre Email"
                  label="Email *"
                  name="username"
                  onChange={handleChange}
                  type="email"
                  value={credentials.username}
                  error={!!error}
                  helperText={error}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  placeholder="Votre mot de passe"
                  label="Mot de passe *"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={credentials.password}
                  error={!!error}
                  helperText={error}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2">
                  Les champs marqués d'une * sont obligatoires.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                >
                  Se connecter
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Link to="forgottenPassword">
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                  >
                    Mot de passe oublié?{" "}
                    <LearnMoreLink title="Réinitialiser." />
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Section>
    </>
  );
};

export default LoginPage;
