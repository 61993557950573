import axios from "axios";
import { API_URL } from "../config";

/**
 * Permet de récupéré les condition générale au format html
 * @return Text HTML
 */
function getTerms() {
  return axios.get(API_URL + "terms-conditions");
}

/**
 * Permet d'accepter les terms
 * @return Réponse JSON
 */
function acceptTerms() {
  return axios.put(API_URL + "acceptTerms");
}

const exportedObject = {
  getTerms,
  acceptTerms,
};

export default exportedObject;
