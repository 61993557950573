import axios from "axios";
import { MARKETING_API } from "../config";

/**
 * Permet de récupéré la campagne marketing actuelle
 * @return Tableau de formation
 */
function getCampaign() {
  return axios.get(MARKETING_API + "/active");
}

/**
 * Increment le nombre de clic
 * @return
 */
function incrementClic(id) {
  return axios.put(MARKETING_API + "/incrementCampaign/" + id);
}

const exportedObject = {
  getCampaign,
  incrementClic,
};

export default exportedObject;
