import React, { useState, useEffect, lazy, Suspense } from "react";
import { HashRouter, Route, Switch, withRouter } from "react-router-dom";

import AuthContext from "./js/contexts/AuthContext";
import authAPI from "./js/Services/authAPI";
import PrivateRoute from "./js/components/PrivateRoute";

import Navbar from "./js/components/Navbar/Navbar";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import "./App.css";

import HomePage from "./js/Pages/HomePage";
import LoginPage from "./js/Pages/LoginPage";
import RegisterPage from "./js/Pages/RegisterPage";
import RegisterSuccessPage from "./js/Pages/RegisterSuccessPage";
import marketingAPI from "./js/Services/marketingAPI";

const SurgeriesPage = lazy(() => import("./js/Pages/SurgeriesPage"));
const SurgeriePage = lazy(() => import("./js/Pages/surgeriePage/SurgeryPage"));
const ConsultationsPage = lazy(() => import("./js/Pages/ConsultationsPage"));
const ConsultationPage = lazy(() => import("./js/Pages/ConsultationPage"));
const GardesPage = lazy(() => import("./js/Pages/GardesPage"));
const GardePage = lazy(() => import("./js/Pages/GardePage"));
const FormationsPage = lazy(() => import("./js/Pages/FormationsPage"));
const FormationPage = lazy(() => import("./js/Pages/FormationPage"));
const FavoritesPage = lazy(() => import("./js/Pages/FavoritesPage"));
const FavoritePage = lazy(() => import("./js/Pages/FavoritePage"));
const YearsPage = lazy(() => import("./js/Pages/YearsPage"));
const YearPage = lazy(() => import("./js/Pages/YearPage"));
const SurgeonsPage = lazy(() => import("./js/Pages/SurgeonsPage"));
const SurgeonPage = lazy(() => import("./js/Pages/SurgeonPage"));
const FrontPage = lazy(() => import("./js/Pages/FrontPage"));
const TestPage = lazy(() => import("./js/Pages/surgeriePage/SurgeryPage"));

authAPI.setup();

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    authAPI.isAuthenticated()
  );
  const [userName, setUserName] = useState(authAPI.getIdentity());
  const [role, setRole] = useState(authAPI.getRole());
  const [loading, setLoading] = useState(false);
  const [years, setYears] = useState();
  const [allImagesLoaded, setAllImagesLoaded] = useState(false); // État pour surveiller si toutes les images sont chargées

  const NavbarWithRouter = withRouter(Navbar);

  useEffect(() => {
    const fetchAndPreloadCampaign = async () => {
      try {
        const response = await marketingAPI.getCampaign();

        if (response.status === 200 && response.data) {
          localStorage.setItem("campaign", JSON.stringify(response.data));
          console.log(
            "localStorage mis à jour avec la nouvelle campagne :",
            response.data
          );

          // Précharger les images de la campagne
          const { formats } = response.data;
          const imageUrls = Object.values(formats);

          await preloadImages(imageUrls);
          setAllImagesLoaded(true); // Toutes les images sont chargées
        } else {
          localStorage.removeItem("campaign");
          console.log("Aucune campagne active, suppression du localStorage");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la campagne :", error);
        localStorage.removeItem("campaign");
      }
    };

    const preloadImages = (urls) => {
      return Promise.all(
        urls.map(
          (url) =>
            new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(url);
              img.onerror = () =>
                reject(new Error(`Erreur de chargement pour ${url}`));
              img.src = url;
            })
        )
      );
    };

    fetchAndPreloadCampaign();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        userName,
        setUserName,
        role,
        setRole,
        loading,
        setLoading,
        years,
        setYears,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <HashRouter>
          <main>
            <Suspense fallback={<LinearProgress />}>
              <NavbarWithRouter />
              {loading && <LinearProgress />}

              <Switch>
                <PrivateRoute
                  path="/front"
                  component={FrontPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/surgeries/:id"
                  component={SurgeriePage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/surgeries"
                  component={SurgeriesPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/consultations/:id"
                  component={ConsultationPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/consultations"
                  component={ConsultationsPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/gardes/:id"
                  component={GardePage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/gardes"
                  component={GardesPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/formations/:id"
                  component={FormationPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/formations"
                  component={FormationsPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/favorites/:id"
                  component={FavoritePage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/favorites"
                  component={FavoritesPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/years/:id"
                  component={YearPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/years"
                  component={YearsPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/surgeons/:id"
                  component={SurgeonsPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/surgeon/:id"
                  component={SurgeonPage}
                  theme={theme}
                />
                <PrivateRoute
                  path="/test/:id"
                  component={TestPage}
                  theme={theme}
                />
                <Route
                  path="/register"
                  component={RegisterPage}
                  theme={theme}
                />
                <Route
                  path="/registerSuccess"
                  component={RegisterSuccessPage}
                  theme={theme}
                />
                <Route path="/login" component={LoginPage} theme={theme} />
                <Route path="/" component={HomePage} theme={theme} />
              </Switch>
            </Suspense>
          </main>
        </HashRouter>
      </MuiThemeProvider>
    </AuthContext.Provider>
  );
}

export default App;
